<div class="main-tab-content">
  <form>
    <fieldset [formGroup]="questionsForm">
      <div formArrayName="array">
        <div
          *ngFor="let value of csrdQuestions; let i = index"
          [ngSwitch]="true"
        >
          <div
            [ngClass]="{
              'group-questions': csrdQuestions[i + 1]?.TEXT === '',
              'mb-2': csrdQuestions[i + 1]?.TEXT !== '',
              'mt-0': csrdQuestions[i + 1]?.TEXT !== ''
            }"
            class="form-group"
            [formGroupName]="i"
            [ngStyle]="getFormGroupStyle(value)"
          >
            <div [attr.data-id]="value?.ID">
              <ng-container *ngIf="value?.TEXT">
                <markdown>
                  <label class="label">
                    {{ i + 1 }}. <span> {{ value?.TEXT }}</span>
                  </label>
                </markdown>
              </ng-container>

              <!-- Handling different input types -->
              <fieldset *ngSwitchDefault [disabled]="value?.DISABLED">
                <input
                  class="form-control customInput input-generic"
                  [id]="value?.ID"
                  (keydown.space)="utilService.restrictLeadingSpace($event)"
                  autocomplete="off"
                  placeholder="{{ value?.PLACEHOLDER }}"
                  formControlName="ANSWERS"
                  minlength="{{ value?.MIN_VALUE }}"
                  maxlength="{{ value?.MAX_VALUE }}"
                  [ngClass]="{
                    errorInputBorder:
                      (getArrayControls()
                        [i].get('ANSWERS')
                        ?.hasError('minlength') ||
                        getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('maxlength')) &&
                      getArrayControls()[i].get('ANSWERS')?.touched
                  }"
                />
                <!-- Error message for input validation -->
                <div
                  class="d-flex pt-2"
                  id="error_message_{{ value?.ID }}"
                  [ngClass]="
                    (getArrayControls()
                      [i].get('ANSWERS')
                      ?.hasError('minlength') ||
                      getArrayControls()
                        [i].get('ANSWERS')
                        ?.hasError('maxlength')) &&
                    getArrayControls()[i].get('ANSWERS')?.touched
                      ? 'visible'
                      : 'invisible'
                  "
                >
                  <img
                    src="../../../assets/images/exclamation-circle.svg"
                    alt="Error"
                    class="align-self-start"
                  />
                  <span
                    class="error px-1"
                    *ngIf="
                        getArrayControls()?.[i]?.get('ANSWERS')?.hasError('minlength') ||
                        getArrayControls()?.[i]?.get('ANSWERS')?.hasError('maxlength')
                        "
                    >Maximaal aantal tekens voor deze vraag is bereikt</span
                  >
                </div>
              </fieldset>
              <!-- DropDown start -->
              <fieldset
                *ngSwitchCase="value?.TYPE === 'DROPDOWN'"
                [disabled]="value?.DISABLED"
              >
                <div style="width: 50%">
                  <searchable-dropdown
                    [formGroup]="dropDownForm"
                    [selectOptions]="dropDownOptions"
                    [id]="value?.ID"
                    controlName="dropDown"
                    (selectedOption)="onOptionSelected($event, value)"
                    [readOnly]="value?.DISABLED"
                    [disabled]="value?.DISABLED"
                  ></searchable-dropdown>
                </div>
              </fieldset>
              <!-- Dropdown end -->
              <!-- Textarea start -->
              <fieldset
                *ngSwitchCase="value?.TYPE === 'TEXT_AREA'"
                [disabled]="value?.DISABLED"
              >
                <textarea
                  [id]="value?.ID"
                  class="form-control text-area"
                  (keydown.space)="utilService.restrictLeadingSpace($event)"
                  autocomplete="off"
                  placeholder="{{ value?.PLACEHOLDER }}"
                  formControlName="ANSWERS"
                  minlength="{{ value?.MIN_VALUE }}"
                  maxlength="{{ value?.MAX_VALUE }}"
                  [ngClass]="{
                    errorInputBorder:
                      (getArrayControls()
                        [i].get('ANSWERS')
                        ?.hasError('minlength') ||
                        getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('maxlength')) &&
                      getArrayControls()[i].get('ANSWERS')?.touched
                  }"
                ></textarea>
                <div
                  class="d-flex pt-2"
                  id="error_message_{{ value?.ID }}"
                  [ngClass]="
                    (getArrayControls()
                      [i].get('ANSWERS')
                      ?.hasError('minlength') ||
                      getArrayControls()
                        [i].get('ANSWERS')
                        ?.hasError('maxlength')) &&
                    getArrayControls()[i].get('ANSWERS')?.touched
                      ? 'visible'
                      : 'invisible'
                  "
                >
                  <img
                    src="../../../assets/images/exclamation-circle.svg"
                    alt="Error"
                    class="align-self-start"
                  />
                  <span
                    class="error px-1"
                    *ngIf="
                      getArrayControls()?.[i]?.get('ANSWERS')?.hasError('minlength') ||
                      getArrayControls()?.[i]?.get('ANSWERS')?.hasError('maxlength')
                      "
                    >Maximaal aantal tekens voor deze vraag is bereikt</span
                  >
                </div>
              </fieldset>
              <!-- Textarea end -->
              <!--  Number start -->
              <fieldset
                *ngSwitchCase="
                  value?.TYPE === 'NUMBER' || value?.TYPE === 'PERCENTAGE'
                "
                [disabled]="value?.DISABLED"
              >
                <div
                  *ngIf="!value?.DISABLED; else regularNumberInput"
                  [class.number-row]="value?.ID > 55 && value?.ID < 68"
                  [style.display]="
                    !(value?.ID > 55 && value?.ID < 68) ? '' : 'block'
                  "
                >
                  <label
                    class="label"
                    [style.width]="'40%'"
                    [style.display]="
                      !(value?.ID > 55 && value?.ID < 68) ? 'none' : ''
                    "
                  >
                    <span [innerHTML]="value?.TEXT"> </span>
                  </label>
                  <input
                    [class.form-control]="!(value?.ID > 55 && value?.ID < 68)"
                    [class.customInput]="!(value?.ID > 55 && value?.ID < 68)"
                    [class.input-generic]="!(value?.ID > 55 && value?.ID < 68)"
                    [id]="value?.ID"
                    (keydown.space)="utilService.restrictLeadingSpace($event)"
                    autocomplete="off"
                    placeholder="{{ value?.PLACEHOLDER }}"
                    formControlName="ANSWERS"
                    onlynumber
                    max="{{ value?.MAX_VALUE }}"
                    min="{{ value?.MIN_VALUE }}"
                    maxlength="{{ value?.MAX_VALUE?.length }}"
                    [ngClass]="{
                      errorInputBorder:
                        (getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('min') ||
                          getArrayControls()
                            [i].get('ANSWERS')
                            ?.hasError('max')) &&
                        getArrayControls()[i].get('ANSWERS')?.touched
                    }"
                  />
                  <div
                    class="d-flex pt-2"
                    id="error_message_{{ value?.ID }}"
                    [ngClass]="
                      (getArrayControls()[i].get('ANSWERS')?.hasError('min') ||
                        getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('max')) &&
                      getArrayControls()[i].get('ANSWERS')?.touched
                        ? 'visible'
                        : 'invisible'
                    "
                  >
                    <img
                      src="../../../assets/images/exclamation-circle.svg"
                      alt="Error"
                      class="align-self-start"
                    />
                    <span
                      class="error px-1"
                      *ngIf="
                          getArrayControls()?.[i]?.get('ANSWERS')?.hasError('min') ||
                          getArrayControls()?.[i]?.get('ANSWERS')?.hasError('max')
                          "
                      >Maximale toegestane waarde is
                      {{ value?.MAX_VALUE }}</span
                    >
                  </div>
                </div>
                <ng-template #regularNumberInput>
                  <input
                    class="form-control customInput input-generic"
                    [id]="value?.ID"
                    (keydown.space)="utilService.restrictLeadingSpace($event)"
                    autocomplete="off"
                    placeholder="{{ value?.PLACEHOLDER }}"
                    formControlName="ANSWERS"
                    onlynumber
                    max="{{ value?.MAX_VALUE }}"
                    min="{{ value?.MIN_VALUE }}"
                    maxlength="{{ value?.MAX_VALUE?.length }}"
                    [ngClass]="{
                      errorInputBorder:
                        (getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('min') ||
                          getArrayControls()
                            [i].get('ANSWERS')
                            ?.hasError('max')) &&
                        getArrayControls()[i].get('ANSWERS')?.touched
                    }"
                  />
                  <div
                    class="d-flex pt-2"
                    id="error_message_{{ value?.ID }}"
                    [ngClass]="
                      (getArrayControls()[i].get('ANSWERS')?.hasError('min') ||
                        getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('max')) &&
                      getArrayControls()[i].get('ANSWERS')?.touched
                        ? 'visible'
                        : 'invisible'
                    "
                  >
                    <img
                      src="../../../assets/images/exclamation-circle.svg"
                      alt="Error"
                      class="align-self-start"
                    />
                    <span
                      class="error px-1"
                      *ngIf="
                          getArrayControls()?.[i]?.get('ANSWERS')?.hasError('min') ||
                          getArrayControls()?.[i]?.get('ANSWERS')?.hasError('max')
                          "
                      >Maximale toegestane waarde is
                      {{ value?.MAX_VALUE }}</span
                    >
                  </div>
                </ng-template>
              </fieldset>
              <!--  Number end -->
              <!-- Multi input start -->
              <ng-container
                *ngSwitchCase="value?.TYPE === 'MULTI_NUMBER'"
                formArrayName="answerlabel"
              >
                <div
                  *ngFor="
                    let labelValue of value?.ANSWER_LABELS;
                    let idx = index
                  "
                  class="d-block d-md-flex"
                >
                  <fieldset
                    [formGroupName]="idx"
                    [disabled]="value?.DISABLED"
                    class="multi-number"
                  >
                    <label class="label w-50"> {{ labelValue }} </label>
                    <input
                      class="w-50 input-generic"
                      placeholder="{{ value?.PLACEHOLDER }}"
                      (keydown.space)="utilService.restrictLeadingSpace($event)"
                      autocomplete="off"
                      formControlName="ANSWER"
                      onlynumber
                      max="{{ value?.MAX_VALUE }}"
                      min="{{ value?.MIN_VALUE }}"
                      maxlength="{{ value?.MAX_VALUE?.length }}"
                      [ngClass]="{
                        errorInputBorder:
                          (getAnswerLabelControls(i)
                            [idx].get('ANSWER')
                            ?.hasError('min') ||
                            getAnswerLabelControls(i)
                              [idx].get('ANSWER')
                              ?.hasError('max')) &&
                          getAnswerLabelControls(i)[idx].get('ANSWER')?.touched
                      }"
                    />
                  </fieldset>
                  <div
                    class="d-flex mb-3 ms-3 align-items-center"
                    id="error_message_{{ value?.ID }}_{{ idx }}"
                    [ngClass]="
                      (getAnswerLabelControls(i)
                        [idx].get('ANSWER')
                        ?.hasError('min') ||
                        getAnswerLabelControls(i)
                          [idx].get('ANSWER')
                          ?.hasError('max')) &&
                      getAnswerLabelControls(i)[idx].get('ANSWER')?.touched
                        ? 'visible'
                        : 'invisible'
                    "
                  >
                    <img
                      src="../../../assets/images/exclamation-circle.svg"
                      alt="Error"
                    />
                    <span
                      class="error px-1"
                      *ngIf="
                        getAnswerLabelControls(i)
                          [idx].get('ANSWER')
                          ?.hasError('min') ||
                        getAnswerLabelControls(i)
                          [idx].get('ANSWER')
                          ?.hasError('max')
                      "
                      >Maximale toegestane waarde is
                      {{ value?.MAX_VALUE }}</span
                    >
                  </div>
                </div>
              </ng-container>
              <!-- Multi input end -->
              <!-- Checkbox start -->
              <ng-container
                *ngSwitchCase="value?.TYPE === 'CHECKBOX'"
                formArrayName="answerlabel"
              >
                <div
                  *ngFor="
                    let labelValue of value?.ANSWER_LABELS;
                    let idx = index
                  "
                  class="d-block d-md-flex"
                >
                  <fieldset [formGroupName]="idx" class="checkbox-input-label">
                    <label class="label w-100">
                      <input
                        type="checkbox"
                        formControlName="ANSWER"
                        checked="value?.ANSWER === 'true'"
                        (change)="
                          value?.RELATED_QUESTIONS?.length &&
                            onAnsweringCheckbox(value, idx)
                        "
                      />
                      {{ labelValue }}
                    </label>
                  </fieldset>
                </div>
              </ng-container>
              <!-- Checkbox end -->
              <!-- Date start -->
              <fieldset
                *ngSwitchCase="value?.TYPE === 'DATE'"
                [disabled]="value?.DISABLED"
              >
                <input
                  class="form-control customInput input-generic"
                  placeholder="{{ value?.PLACEHOLDER }}"
                  name="dp"
                  [id]="value?.ID"
                  (keydown.space)="utilService.restrictLeadingSpace($event)"
                  autocomplete="off"
                  formControlName="ANSWERS"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  (click)="d.toggle()"
                  ngbDatepicker
                  readonly
                  [footerTemplate]="footerTemplate"
                  (keyup)="backSpace($event, i, 'ANSWERS')"
                  [ngClass]="{
                    errorInputBorder:
                      getArrayControls()
                        [i].get('ANSWERS')
                        ?.hasError('ngbDate') &&
                      getArrayControls()[i].get('ANSWERS')?.touched
                  }"
                />
                <ng-template #footerTemplate>
                  <hr class="my-0" />
                  <button
                    class="btn btn-primary btn-sm m-2 float-start"
                    (click)="clearDate(i, 'ANSWERS')"
                  >
                    Clear
                  </button>
                  <button
                    class="btn btn-secondary btn-sm m-2 float-end"
                    (click)="d.close()"
                  >
                    Close
                  </button>
                </ng-template>
              </fieldset>
              <!-- Date end -->
              <!-- Radio button start -->
              <div
                class="d-flex radio align-items-center mt-3"
                *ngSwitchCase="value?.TYPE === 'RADIO'"
              >
                <div
                  *ngFor="
                    let ANSWER_LABEL of value?.ANSWER_LABELS;
                    let alId = index
                  "
                  class="d-block d-md-flex"
                >
                  <p class="me-5 mb-0">
                    <input
                      type="radio"
                      class="input-generic"
                      id="{{ ANSWER_LABEL }}_{{ value?.ID }}"
                      formControlName="ANSWERS"
                      value="{{ ANSWER_LABEL }}"
                      (change)="
                        value?.RELATED_QUESTIONS?.length &&
                          onAnsweringRadioButton(
                            value?.RELATED_QUESTIONS,
                            ANSWER_LABEL,
                            value?.DEPENDENT_VALUE
                          )
                      "
                    />
                    <label
                      for="{{ ANSWER_LABEL }}_{{ value?.ID }}"
                      class="answer"
                      >{{ ANSWER_LABEL }}</label
                    >
                  </p>
                </div>
              </div>
              <!-- Radio button end -->

              <!-- Boolean button start -->
              <div
                class="d-flex radio align-items-center mt-3"
                *ngSwitchCase="
                  value?.TYPE === 'BOOLEAN' || value?.TYPE === 'UNKOWN_BOOLEAN'
                "
              >
                <ng-container
                  *ngIf="
                    (value?.ID == 28 && !value.DISABLED) || value?.ID != 28
                  "
                >
                  <p class="me-5 mb-0">
                    <input
                      type="radio"
                      class="input-generic"
                      id="ja_{{ i }}"
                      formControlName="ANSWERS"
                      value="true"
                      (change)="
                        value?.RELATED_QUESTIONS?.length &&
                          onAnsweringRadioButton(
                            value?.RELATED_QUESTIONS,
                            true,
                            true
                          )
                      "
                    />
                    <label for="ja_{{ i }}" class="answer">Ja</label>
                  </p>

                  <p class="me-5 mb-0">
                    <input
                      type="radio"
                      class="input-generic"
                      id="nee_{{ i }}"
                      formControlName="ANSWERS"
                      value="false"
                      (change)="
                        value?.RELATED_QUESTIONS?.length &&
                          onAnsweringRadioButton(
                            value?.RELATED_QUESTIONS,
                            false,
                            true
                          )
                      "
                    />
                    <label for="nee_{{ i }}" class="answer">Nee</label>
                  </p>
                  <p class="mb-0" *ngIf="value?.TYPE === 'BOOLEAN_UNKNOWN'">
                    <input
                      type="radio"
                      class="input-generic"
                      id="onbekend_{{ i }}"
                      formControlName="ANSWERS"
                      value="unknown"
                      (change)="
                        value?.RELATED_QUESTIONS?.length &&
                          onAnsweringRadioButton(
                            value?.RELATED_QUESTIONS,
                            undefined,
                            true
                          )
                      "
                    />
                    <label for="onbekend_{{ i }}" class="answer"
                      >Onbekend</label
                    >
                  </p>
                </ng-container>
              </div>
              <!-- Radio button end -->
              <div
                *ngSwitchCase="value?.TYPE === 'GROUPED'"
                class="grouped-question-container"
                [ngClass]="{ disabled: value?.DISABLED }"
              >
                <div
                  *ngFor="
                    let groupSection of questionsForm?.value?.array[i]
                      ?.GROUPED_ANSWERS;
                    let gi = index;
                    trackBy: trackByIndex
                  "
                  formArrayName="GROUPED_ANSWERS"
                >
                  <!-- Loop through each question in the GROUP array -->
                  <div
                    *ngFor="
                      let groupQuestion of groupSection.ANSWERS;
                      let gQi = index;
                      trackBy: trackByIndex
                    "
                    [formGroupName]="gi"
                    class="grouped-question"
                  >
                    <!-- Access the specific FormGroup in GROUPED_ANSWERS by gQi index -->
                    <div class="mb-2">
                      <!-- Bind to the specific control for answers -->
                      <div formArrayName="ANSWERS">
                        <div [formGroupName]="gQi">
                          <!-- <input
                            class="form-control customInput input-generic"
                            autocomplete="off"
                            placeholder="test"
                          /> -->
                          <input
                            class="form-control customInput input-generic"
                            [id]="value?.ID + gi + gQi"
                            autocomplete="off"
                            placeholder="{{ groupQuestion?.PLACEHOLDER }}"
                            formControlName="ANSWERS"
                            minlength="{{ groupQuestion?.MIN_VALUE }}"
                            maxlength="{{ groupQuestion?.MAX_VALUE }}"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-4">
                    <a
                      class="cta-text"
                      (click)="removeGroup(i, gi)"
                      [ngClass]="{
                        disabled:
                          value.DEFAULT_NUMBER_OF_GROUPS >=
                          questionsForm?.value?.array[i]?.GROUPED_ANSWERS.length
                      }"
                    >
                      Remove this group
                    </a>
                  </div>
                </div>
                <div class="cta-wrapper">
                  <button
                    class="add-btn"
                    (click)="addGroup(i)"
                    [disabled]="
                      value.MAXIMUM_NUMBER_OF_GROUPS <=
                      questionsForm?.value?.array[i]?.GROUPED_ANSWERS.length
                    "
                  >
                    <img
                      src="../../../assets/images/down-arrow.svg"
                      alt="down arrow icon"
                    />
                    <span class="cta-text">
                      Add ({{
                        questionsForm?.value?.array[i]?.GROUPED_ANSWERS.length +
                          1
                      }}
                      / {{ value.MAXIMUM_NUMBER_OF_GROUPS }})
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
